import { doc, getDoc } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { DIVISIONS, EVENT_TYPES, ORGANIZATIONS } from '../constants';

const getEventTypeById = async (orgId, divId, eventTypeId) =>
  getDoc(doc(db, ORGANIZATIONS, orgId, DIVISIONS, divId, EVENT_TYPES, eventTypeId)).then(snapshot =>
    snapshot.data(),
  );

export { getEventTypeById };
