import React from 'react';

import { Select } from 'antd';
import PropTypes from 'prop-types';

import { db } from 'firebase/firebase';
import { collection } from 'firebase/firestore';

import { useFirestoreQuery } from '../../packages/utils/hooks';
import AvatarLocation from 'components/Location/AvatarLocation';
import styles from './styles.module.less';
import IntlMessages from 'util/IntlMessages';

const DropDownLoc = ({ orgId, divId, onChange, locationValue, ...props }) => {
  const { mode } = props;

  const orgLocationsRef = orgId && collection(db, 'organizations', orgId, 'locations');
  const divLocationsRef =
    orgId && divId && collection(db, 'organizations', orgId, 'divisions', divId, 'locations');
  const multipleMode = mode === 'multiple';

  const { data: orgLocations = [] } = useFirestoreQuery(orgLocationsRef, [orgId]);
  const { data: divLocations = [] } = useFirestoreQuery(divLocationsRef, [orgId, divId]);

  let justIdsSelected = [];
  if (multipleMode && locationValue?.length > 0) {
    justIdsSelected = locationValue;
  } else {
    if (locationValue?.item?.id) justIdsSelected.push(locationValue?.item?.id);
  }

  const locations = divLocations.concat(orgLocations);
  const selectedLocations = locations?.filter(loc => justIdsSelected.includes(loc.id));

  const isTypeSelected = loc => {
    const type = loc?.type;
    if (justIdsSelected.includes(loc.id)) return false;
    return !!selectedLocations.find(loc => loc.type === type);
  };

  const handleLocation = loc => {
    let newLocationData;
    if (multipleMode) {
      newLocationData = locations.filter(item => loc.includes(item.id));
    } else {
      newLocationData = locations.filter(
        item => item.id === loc.value && !justIdsSelected.includes(item.id),
      );
    }

    const output = multipleMode ? newLocationData : newLocationData[0];

    onChange(output);
  };

  return (
    <>
      <Select
        {...props}
        disabled={!locations?.length || props?.disabled}
        value={locationValue}
        onChange={handleLocation}
        showSearch
        allowClear
        className={styles.selectContainer}
        optionFilterProp="title"
        placeholder={<IntlMessages id="general.select.locations" />}
      >
        {locations.map(loc => (
          <Select.Option
            value={loc.id}
            key={loc.id}
            title={loc.name}
            disabled={isTypeSelected(loc)}
            style={{ height: '25px' }}
          >
            <div className="gx-flex-row gx-align-items-center gx-guarnic-gap-1">
              <AvatarLocation location={loc} />
              <span>{loc.name}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

DropDownLoc.propTypes = {
  orgId: PropTypes.string.isRequired,
  divId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  locationValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const DropDownLocations = React.memo(DropDownLoc);
