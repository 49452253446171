import { db } from 'firebase/firebase';
import { collection, query } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { ALLOWED_ROLES, getDivsWithAccess } from 'packages/utils/access';
import useFirestoreQueryBatched from '../useFirestoreQueryBatched/useFirestoreQueryBatched';

/**
 * NOTE: Division Id is not required, but if you pass it, we are going to filter the locations by that division
 */
// NOTE: This hook will be replaced by useGetDivLocations, since we are gonna manage hooks independently (orgLocations and divLocations);
const useGetAllLocations = ({ organizationId, divisionId = null }) => {
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[organizationId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);
  let allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));
  if (divisionId) {
    allowedSelectedDivs = allowedSelectedDivs.filter(divId => divId === divisionId);
  }

  const divQueriesList = allowedSelectedDivs.map(divId =>
    query(collection(db, 'organizations', organizationId, 'divisions', divId, 'locations')),
  );
  const { data: divLocations, loading: divLocationLoading } = useFirestoreQueryBatched(
    divQueriesList,
    [selectedDivsIds, divisionId],
  );

  const locQueriesList = [query(collection(db, 'organizations', organizationId, 'locations'))];
  const { data: orgLocations, loading: orgLocationLoading } = useFirestoreQueryBatched(
    locQueriesList,
    [selectedDivsIds],
  );

  const locations = [...divLocations, ...orgLocations];

  return {
    data: locations,
    loading: divLocationLoading || orgLocationLoading,
  };
};

export default useGetAllLocations;
