import { ALLOWED_ROLES, hasAnyAllowedRole } from 'packages/utils/access';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDivLocations, getOrgLocations } from 'util/firebase-operations/locations/get';
import safeExecute from 'util/safeExecute';

export const useGetLocationsGroup = ({ divisionId, groupType }) => {
  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);

  const canListOrgLoc = hasAnyAllowedRole(
    ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST,
    userOrgAccess,
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      safeExecute(async () => {
        setLoading(true);
        let output = [];
        const groupTypeSelected = groupType;

        if (divisionId && groupTypeSelected === 'division') {
          const divLocations = (await getDivLocations(orgId, [divisionId])) || [];
          const orgLocations = canListOrgLoc ? (await getOrgLocations(orgId)) || [] : [];

          output = [...divLocations, ...orgLocations];
        } else if (groupTypeSelected === 'organization') {
          output = canListOrgLoc ? (await getOrgLocations(orgId)) || [] : [];
        }
        setData(output);
      }).finally(() => {
        setLoading(false);
      });
    };

    fetch();
  }, [canListOrgLoc, divisionId, groupType, orgId]);

  return { loading, data };
};
