import React, { createContext } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useGetAllSchedules from 'packages/utils/hooks/collections/useGetAllSchedules';

export const EventsByScheduleContext = createContext();

export const EventsByScheduleProvider = ({ children }) => {
  const [runGetAllSchedules, setRunGetAllSchedules] = React.useState(false);

  const orgId = useSelector(state => state.organizations.organization.id);

  const {
    data: schedules = [],
    loading: schedulesLoading = false,
    error = null,
  } = useGetAllSchedules(orgId, [], runGetAllSchedules);

  return (
    <EventsByScheduleContext.Provider
      value={{
        schedules,
        schedulesLoading,
        error,
        runGetAllSchedules: () => setRunGetAllSchedules(true),
      }}
    >
      {children}
    </EventsByScheduleContext.Provider>
  );
};

EventsByScheduleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
