import { Select } from 'antd';
import { LOC_TYPES } from 'constants/commons';
import React from 'react';
import { useIntl } from 'react-intl';

export default function DropDownLocationTypes({ onChange = () => {}, ...props }) {
  const intl = useIntl();
  const locationTypeOptions = [
    ...Object.values(LOC_TYPES).map(item => ({ label: item, value: item })),
    { label: intl.formatMessage({ id: 'location.type.search' }), value: '' },
  ];

  return (
    <Select
      {...props}
      onChange={onChange}
      showSearch
      options={locationTypeOptions}
      className="gx-mb-0 gx-w-100"
    />
  );
}
