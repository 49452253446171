import { union } from 'lodash';

import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import LocationsList from './screens/LocationsList';
import GpsLocation from './screens/GpsLocation';
import NfcLocation from './screens/NfcLocation';
import QrLocation from './screens/QrLocation';
import UploadLocationFiles from './screens/UploadFiles';
import LocationGroup from './screens/LocationGroup';
import GroupLocations from './screens/GroupLocations';

export default [
  {
    component: LocationsList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: GpsLocation,
    path: PATHS.CREATE_GPS,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: GpsLocation,
    path: PATHS.EDIT_GPS,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.UPDATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: NfcLocation,
    path: PATHS.CREATE_NFC,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: NfcLocation,
    path: PATHS.EDIT_NFC,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.UPDATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: QrLocation,
    path: PATHS.CREATE_QR,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: QrLocation,
    path: PATHS.EDIT_QR,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.UPDATE,
    ),
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: UploadLocationFiles,
    path: PATHS.UPLOAD_FILES,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: LocationGroup,
    path: PATHS.LOCATION_GROUP,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.CREATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.CREATE,
    ),
  },
  {
    component: LocationGroup,
    path: PATHS.EDIT_LOCATION_GROUP,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.UPDATE,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.UPDATE,
    ),
  },
  {
    component: GroupLocations,
    path: PATHS.GROUP_LOCATIONS,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
    allowedRoles: union(
      ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.LIST,
      ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.LIST,
    ),
  },
];
