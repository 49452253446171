import React from 'react';

import { Buffer } from 'buffer';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Avatar, Button, Modal } from 'antd';
import { QRCodeSVG } from 'qrcode.react';

import { AimOutlined, QrcodeOutlined } from '@ant-design/icons';

import styles from './styles.module.less';

const ICONS = {
  GPS: <AimOutlined />,
  QR: <QrcodeOutlined />,
  NFC: <span style={{ fontSize: '8px' }}>NFC</span>,
};

const variants = {
  primary: styles.primary,
  light: styles.light,
};

const downloadQR = () => {
  const svg = document.getElementById('qrcode');
  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = 'qrcode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  const base64Str = Buffer.from(svgData, 'utf8').toString('base64');
  img.src = `data:image/svg+xml;base64,${base64Str}`;
};

const AvatarLocation = ({ type, location }) => {
  const intl = useIntl();

  const qrModal = content => {
    Modal.info({
      okText: intl.formatMessage({ id: 'button.ok' }),
      maskClosable: true,
      icon: null,
      content: (
        <div>
          <QRCodeSVG
            id="qrcode"
            value={content?.token}
            size="100%"
            onClick={downloadQR}
            style={{ cursor: 'pointer' }}
          />
          <Button onClick={downloadQR} type="primary">
            {intl.formatMessage({ id: 'button.download' })}
          </Button>
        </div>
      ),
    });
  };

  const handleAction = {
    GPS: () => null,
    QR: content => qrModal(content),
    NFC: () => null,
  };

  return (
    <Avatar
      onClick={() => handleAction[location?.type](location)}
      className={classNames(styles.locationAvatar, variants[type || 'primary'])}
      icon={ICONS[location?.type?.toUpperCase()]}
      size={24}
    />
  );
};

export default AvatarLocation;
