/* eslint-disable react/jsx-indent */
import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Empty, Modal, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import safeExecute from 'util/safeExecute';
import { LOC_TYPES } from 'constants/commons';
import { InfoCardList } from 'components/InfoCard';
import useFullUrl from 'packages/utils/hooks/useFullUrl';
import CircleButton from 'components/Buttons/CircleButton';
import { ALLOWED_ROLES } from 'packages/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import LocationCounter from 'components/Location/LocationCounter';
import { deleteLocationGroup } from 'packages/locations/screens/LocationGroup/constants';

import LocationInfoCard from 'components/Location/LocationInfoCard';
import { locationDelete } from '../../redux/actions';
import {
  getRouteToEditLocation,
  getRouteToLocationGroup,
  goToGroupLocations,
} from '../../constants';

const { confirm } = Modal;
const DivisionList = ({ data = [], loading = false, refresh = () => {} }) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { goToPath } = useFullUrl();

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const divisions = useSelector(({ user }) => user.profile.data.organizations[orgId].divisions);

  const handleEditLocation = (type, locId, divId) =>
    history.push(getRouteToEditLocation(type, locId, divId), {
      fromOrganization: false,
    });

  const showDeleteConfirm = useCallback(
    loc => {
      const isAGroup = 'locations' in loc;

      confirm({
        cancelText: intl.formatMessage({ id: 'button.no' }),
        content: intl.formatMessage({ id: 'locations.list.deleteModal.content' }),
        icon: <ExclamationCircleOutlined />,
        okText: intl.formatMessage({ id: 'button.yes' }),
        okType: 'danger',
        onOk: () => {
          safeExecute(async () => {
            if (isAGroup) {
              await deleteLocationGroup({ orgId, groupId: loc.id, divId: loc.divisionId });
            } else {
              dispatch(locationDelete(orgId, loc.divId, loc.id));
            }
          })
            .then(() => {
              notification.success({
                message: intl.formatMessage({ id: 'general.save.successful.message' }),
              });
            })
            .finally(() => {
              refresh(prev => !prev);
            });
        },
        title: intl.formatMessage(
          { id: 'locations.list.deleteModal.title' },
          { location: loc.name },
        ),
      });
    },
    [dispatch, intl, refresh, orgId],
  );

  const renderLocationList = loc => {
    const isAGroup = 'locations' in loc;

    if (isAGroup) {
      const gpsCounter = loc.locations.filter(location => location.type === LOC_TYPES.GPS).length;
      const nfcCounter = loc.locations.filter(location => location.type === LOC_TYPES.NFC).length;
      const qrCounter = loc.locations.filter(location => location.type === LOC_TYPES.QR).length;

      return (
        <InfoCardList
          avatar={
            <CircleButton
              onClick={() => goToPath(goToGroupLocations(loc?.id, loc?.divisionId))}
              iconName="workspaces"
              iconSize="sm"
            />
          }
          title={
            <div className="gx-flex-row gx-align-items-center gx-guarnic-gap-2">
              <div className="gx-flex-1">{loc.name}</div>
              <div className="gx-flex-1">
                <LocationCounter gps={gpsCounter} nfc={nfcCounter} qr={qrCounter} />
              </div>
            </div>
          }
          subTitle={divisions[loc.divisionId]?.name || ''}
          buttonItems={[
            {
              iconName: 'edit',
              action: () => history.push(getRouteToLocationGroup(loc.id, loc.divisionId)),
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.UPDATE,
              type: 'primary',
            },
            {
              iconName: 'delete',
              action: () => showDeleteConfirm(loc),
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATION_GROUPS.DELETE,
              type: 'danger',
            },
          ]}
        />
      );
    }

    return (
      <LocationInfoCard
        location={loc}
        buttonItems={[
          {
            iconName: 'edit',
            action: () => handleEditLocation(loc.type, loc.id, loc.divId),
            allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.UPDATE,
            type: 'primary',
          },
          {
            iconName: 'delete',
            action: () => showDeleteConfirm(loc),
            allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.DELETE,
            type: 'danger',
          },
        ]}
      />
    );
  };

  return (
    <>
      {!loading && data?.length > 0 ? (
        data.map(loc => <React.Fragment key={loc?.id}>{renderLocationList(loc)}</React.Fragment>)
      ) : (
        <Empty description={intl.formatMessage({ id: 'location.empty.state' })} />
      )}
    </>
  );
};

DivisionList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
};
export default DivisionList;
