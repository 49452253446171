import { useMemo } from 'react';

import moment from 'moment';

import { db } from 'firebase/firebase';
import { SCHEDULE_TYPES } from 'constants/commons';
import { collection, query, where } from 'firebase/firestore';

import useFirestoreQueryBatched from '../useFirestoreQueryBatched/useFirestoreQueryBatched';
import useGetDivisionsAllowed from '../useGetDivisionsAllowed';

const useGetAllSchedules = (orgId, divIds, run = true) => {
  const { allowedSelectedDivs } = useGetDivisionsAllowed();

  const scheduleRefs = useMemo(() => {
    if (!run) {
      return [];
    }

    if (divIds.length > 0) {
      return divIds.map(divId =>
        query(
          collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
          where('type', '==', SCHEDULE_TYPES.schedule),
        ),
      );
    }

    return allowedSelectedDivs.map(divId =>
      query(
        collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
        where('type', '==', SCHEDULE_TYPES.schedule),
      ),
    );
  }, [allowedSelectedDivs, divIds, orgId, run]);

  const { data, loading, error } = useFirestoreQueryBatched(scheduleRefs, []);

  const todayDate = moment();
  const activeSchedules = [];

  data.forEach(element => {
    const executionDate = moment(element?.schedule?.startAt.toDate());
    const endDate = moment(element?.schedule?.endAt.toDate());

    if (executionDate.isBefore(todayDate) && endDate.isAfter(todayDate)) {
      activeSchedules.push(element);
    }
  });

  return { data: activeSchedules, loading, error };
};

export default useGetAllSchedules;
