import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Select, Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { ClearOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { LOC_TYPES } from '../../constants';

const LocationFilter = ({ handleFilter = () => {}, filters = {}, handleClear = () => {} }) => {
  const intl = useIntl();

  let locationTypeOptions = Object.values(LOC_TYPES).map(item => ({ label: item, value: item }));
  locationTypeOptions = locationTypeOptions.concat({
    label: intl.formatMessage({ id: 'location.type.search' }),
    value: '',
  });

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} sm={6} md={6} xxl={6} xl={6}>
        <Select
          value={filters.locationType}
          onChange={value => {
            handleFilter('locationType', value);
          }}
          showSearch
          style={{ width: '100%' }}
          options={locationTypeOptions}
          className="gx-m-2 gx-w-100"
        />
      </Col>
      <Col xs={24} sm={6} md={6} xxl={6} xl={6}>
        <Input
          className="gx-m-2 gx-w-100"
          value={filters.locationName}
          onChange={e => {
            handleFilter('locationName', e.target.value);
          }}
          placeholder={intl.formatMessage({ id: 'location.placeholder.name' })}
        />
      </Col>
      <Col xs={24} sm={6} md={6} xxl={6} xl={6}>
        <Select
          className="gx-m-2 gx-w-100"
          value={filters.onlyGroups}
          onChange={value => {
            handleFilter('onlyGroups', value);
          }}
          defaultValue={false}
          options={[
            { label: intl.formatMessage({ id: 'general.all' }), value: false },
            { label: intl.formatMessage({ id: 'location.only.groups' }), value: 'groups' },
            { label: intl.formatMessage({ id: 'location.only.locations' }), value: 'locations' },
          ]}
        />
      </Col>
      <Col xs={24} sm={6} md={6} xxl={6} xl={6}>
        <Button
          danger
          icon={<ClearOutlined className="gx-mr-2" />}
          className="gx-m-2"
          onClick={handleClear}
        >
          <IntlMessages id="general.clear" />
        </Button>
      </Col>
    </Row>
  );
};

LocationFilter.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    locationType: PropTypes.string,
    locationName: PropTypes.string,
    onlyGroups: PropTypes.bool,
  }),
};

export default LocationFilter;
