import { ref, uploadBytes } from 'firebase/storage';
import { storage } from 'firebase/firebase';

export const REDUCER_INDEX = 'locations';
export const BASE_URL = 'locations';

export const PATHS = {
  BASE_URL,
  CREATE_GPS: `${BASE_URL}/GPS/create`,
  EDIT_GPS: `${BASE_URL}/GPS/edit/:locId/:divId?`,
  CREATE_NFC: `${BASE_URL}/NFC/create`,
  EDIT_NFC: `${BASE_URL}/NFC/edit/:locId/:divId?`,
  CREATE_QR: `${BASE_URL}/QR/create`,
  EDIT_QR: `${BASE_URL}/QR/edit/:locId/:divId?`,
  UPLOAD_FILES: `${BASE_URL}/upload`,
  LOCATION_GROUP: `${BASE_URL}/location_group`,
  EDIT_LOCATION_GROUP: `${BASE_URL}/location_group/edit?locationGroupId=:locationGroupId&divId=:divId`,
  GROUP_LOCATIONS: `${BASE_URL}/group_locations/:locationGroupId/:divId`,
};

export const getRouteToCreateLocation = type => PATHS[`CREATE_${type}`];

export const getRouteToEditLocation = (type, locId, divId = '') =>
  PATHS[`EDIT_${type}`].replace(':locId', locId).replace(':divId?', divId);

export const getRouteToLocationGroup = (locId = null, divId = null) =>
  PATHS.EDIT_LOCATION_GROUP.replace(':locationGroupId', locId).replace(':divId', divId);

export const getRouteUploadLocationFile = () => PATHS.UPLOAD_FILES;

export const goToRouteLocationGroup = () => PATHS.LOCATION_GROUP;

export const goToGroupLocations = (locationGroupId, divId) =>
  PATHS.GROUP_LOCATIONS.replace(':locationGroupId', locationGroupId).replace(
    ':divId',
    divId ?? 'null',
  );

export const LOC_TYPES = {
  GPS: 'GPS',
  NFC: 'NFC',
  QR: 'QR',
};

export const QR_COLUMNS_NAME = ['CODE', 'NAME'];
export const GPS_COLUMNS_NAME = ['NAME', 'LAT', 'LNG', 'METERS', 'OUTSIDE'];
export const NFC_COLUMNS_NAME = ['NAME', 'NFC'];

export const HEADERS_NAME = {
  QR: QR_COLUMNS_NAME,
  GPS: GPS_COLUMNS_NAME,
  NFC: NFC_COLUMNS_NAME,
};

export const ALLOWED_FILE_TYPES = ['text/csv'];

export const uploadFile = ({ file, orgId, divId }) => {
  const fileName = file?.name || 'location.csv';
  const metadata = {
    contentType: file?.type,
  };

  const path = divId
    ? `organizations/${orgId}/divisions/${divId}/locations_imports/${fileName}`
    : `organizations/${orgId}/locations_imports/${fileName}`;

  const storageRef = ref(storage, path);
  return uploadBytes(storageRef, file, metadata);
};

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 24,
  },
};

export const LOCATIONS_FORMS = {
  LOCATION_GROUP: 'location_group',
};

export const GROUP_LOCATIONS_BY_OPTIONS = [
  {
    value: 'division',
    label: 'Division',
  },
  {
    value: 'organization',
    label: 'Organization',
  },
];
