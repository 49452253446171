import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Modal, Radio } from 'antd';

import { db } from 'firebase/firebase';
import IntlMessages from 'util/IntlMessages';
import { ALLOWED_ROLES, useFirestoreQuery } from 'packages/utils';
import { collection, query } from 'firebase/firestore';
import { QuestionCircleFilled } from '@ant-design/icons';
import { KeyValueWidget } from 'components/KeyValueWidget';
import CircleButton from 'components/Buttons/CircleButton';
import Title from 'components/BoxContainer/components/Title';
import { DropDownLocations } from 'components/DropDownLocations';
import useGetOrgLocationGroups from 'packages/utils/hooks/collections/useGetOrgLocationGroups';

import DropDownLocationGroup from './DropDownLocationGroup';
import LocationGroup from 'packages/locations/screens/LocationGroup';
import classNames from 'classnames';
import styles from './styles.module.less';
import ComponentBlocker from 'components/ComponentBlocker';

const INIT_DATA = {
  isAGroup: -1,
  data: null,
};

/**
 * LocationCombine can have 3 states:
 *  - None: -1
 * - Location: false
 * - LocationGroup: true
 */
export const getLocationCombinerStatus = (data = null) => {
  if (!data) return -1;

  const hasLocations = data?.locations && !!data.locations.length > 0;
  const hasLocationGroups = data?.locationGroup && !!Object.keys(data.locationGroup).length > 0;

  if (!hasLocations && !hasLocationGroups) {
    return -1;
  }

  return hasLocationGroups ? true : false;
};

function LocationCombiner({
  divisionId = null,
  value = null,
  onChange = () => {},
  className,
  ...props
}) {
  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const intl = useIntl();

  const [location, setLocation] = useState(INIT_DATA);
  const [isVisible, setIsVisible] = useState(false);
  const [openLocationGroupForm, setOpenLocationGroupForm] = useState(false);

  const queryRef = divisionId
    ? query(collection(db, 'organizations', orgId, 'divisions', divisionId, 'location_groups'))
    : null;

  const { data: divGroupLocations = [], loading: divGroupLocationsLoading } = useFirestoreQuery(
    queryRef,
    [orgId, divisionId],
  );

  const { data: orgGroupLocations = [], loading: orgGroupLocationsLoading } =
    useGetOrgLocationGroups([openLocationGroupForm]);

  const mainLoading = divGroupLocationsLoading || orgGroupLocationsLoading;

  const locationGroupsMerged = [...divGroupLocations, ...orgGroupLocations];

  const onChangeLocationGroups = loc => {
    let newLoc = locationGroupsMerged.find(item => item.id === loc);
    const output = {
      isAGroup: true,
      data: newLoc,
    };
    setLocation(output);
    onChange(output);
  };

  const onChangeLocations = locations => {
    const output = {
      isAGroup: false,
      data: locations,
    };
    setLocation(output);
    onChange(output);
  };

  useEffect(() => {
    if (value) {
      setLocation(prev => ({
        ...prev,
        data: value?.data,
        isAGroup: value?.isAGroup,
      }));
    }
  }, [value]);

  return (
    <div className={classNames('gx-w-100', styles.locationCombinerMain, className)}>
      <div className="gx-flex-row gx-align-items-center gx-w-100">
        <Radio.Group
          disabled={mainLoading || props?.disabled}
          value={location.isAGroup}
          defaultValue={-1}
          onChange={val => {
            const newLoc = {
              data: null,
              isAGroup: val.target.value,
            };
            setLocation(newLoc);
            onChange(newLoc);
          }}
        >
          <Radio.Button value={-1}>
            <IntlMessages id="triggers.view.sectionTitle.none" />
          </Radio.Button>
          <Radio.Button value={false}>
            <IntlMessages id="general.locations" />
          </Radio.Button>
          <Radio.Button value>
            <IntlMessages id="general.locationsGroup" />
          </Radio.Button>
        </Radio.Group>
        <Button className="gx-m-0" type="text" onClick={() => setIsVisible(true)}>
          <QuestionCircleFilled />
        </Button>
      </div>
      {location.isAGroup !== -1 && (
        <div className="gx-w-100">
          {location.isAGroup ? (
            <KeyValueWidget
              value={
                <div className="gx-flex-row gx-guarnic-gap-2">
                  <div className="gx-flex-1">
                    <DropDownLocationGroup
                      onChange={onChangeLocationGroups}
                      options={locationGroupsMerged}
                      value={location?.data?.id}
                      disabled={mainLoading || props?.disabled}
                    />
                  </div>
                  <ComponentBlocker
                    allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.CREATE}
                    divId={divisionId}
                  >
                    <div className="gx-flex-2">
                      <CircleButton onClick={() => setOpenLocationGroupForm(true)} iconName="add" />
                    </div>
                  </ComponentBlocker>
                </div>
              }
            />
          ) : (
            <KeyValueWidget
              value={
                <DropDownLocations
                  mode="multiple"
                  onChange={onChangeLocations}
                  divId={divisionId}
                  orgId={orgId}
                  locationValue={location?.data?.map(loc => loc.id)}
                  disabled={mainLoading || props?.disabled}
                />
              }
            />
          )}
        </div>
      )}

      <Modal
        title={intl.formatMessage({ id: 'general.help' })}
        open={isVisible}
        footer={null}
        onCancel={() => setIsVisible(false)}
      >
        <div>
          <Title.SubTitle value={<IntlMessages id="general.locations" />} />
          <span>
            <IntlMessages id="location.help.locations.description" />
          </span>
        </div>

        <div className="gx-mt-2">
          <Title.SubTitle value={<IntlMessages id="general.locationsGroup" />} />
          <span>
            <IntlMessages id="location.help.locationsGroup.description" />
          </span>
        </div>
      </Modal>

      {openLocationGroupForm && (
        <LocationGroup onClose={() => setOpenLocationGroupForm(false)} divisionId={divisionId} />
      )}
    </div>
  );
}

LocationCombiner.propTypes = {
  divisionId: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default React.memo(LocationCombiner);
