import { Drawer } from 'antd';
import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import LocationsByGroup from 'containers/LocationsByGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

export default function GroupLocations({ divisionId, locGroupId, onClose }) {
  const params = useParams();
  const history = useHistory();
  const divId = divisionId || (params?.divId !== 'null' ? params?.divId : null);
  const locationGroupId =
    locGroupId || (params?.locationGroupId !== 'null' ? params?.locationGroupId : null);

  const [locationsGroup, setLocationsGroup] = React.useState(null);

  const onGoBack = () => {
    const action = onClose || history.goBack;
    action();
  };

  return (
    <Drawer open closable={false} footer={null}>
      <BoxContainer content shadow fixed>
        <FilterContainer
          title={<Title.Header value={locationsGroup?.name || ''} />}
          goBack={onGoBack}
        />
      </BoxContainer>
      <BoxContainer content>
        <LocationsByGroup
          divisionId={divId}
          locationGroupId={locationGroupId}
          onGetLocationsGroup={setLocationsGroup}
        />
      </BoxContainer>
    </Drawer>
  );
}

GroupLocations.propTypes = {
  divisionId: PropTypes.string,
  locGroupId: PropTypes.string,
  onClose: PropTypes.func,
};
