import React from 'react';
import { KeyValueWidget } from 'components/KeyValueWidget';
import AvatarLocation from './AvatarLocation';

const Location = ({ location, type = 'primary' }) => {
  return (
    <KeyValueWidget
      value={
        <div className="gx-flex-row gx-w-100 gx-flex-nowrap">
          <div className="gx-mr-2">
            <AvatarLocation location={location} type={type} />
          </div>
          <div className="gx-align-self-center">
            <span className="gx-font-weight-medium gx-guarnic-body">{location?.type}</span>
            {' | '}
            <span className="gx-guarnic-body">{location?.name}</span>
          </div>
        </div>
      }
    />
  );
};

export { Location };
