const { ERROR_MESSAGES } = require('constants/ErrorMessages');

class CustomError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
    this.message = message;
  }
}

const BuildingError = message => {
  const output = message;

  if (output.includes('requires an index')) {
    return ERROR_MESSAGES.FIREBASE_INDEX;
  }

  if (output.includes('Cannot read properties of undefined')) {
    return ERROR_MESSAGES.PROP_NOT_FOUND;
  }

  return ERROR_MESSAGES.DEFAULT;
};

module.exports = { CustomError, BuildingError };
