import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, Row, Switch, TimePicker, notification } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useHistory, useParams } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { db } from 'firebase/firebase';
import { LOC_TYPES } from 'packages/locations';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';
import { doc, getDoc } from 'firebase/firestore';

import { removeAuditFields } from 'packages/utils';
import safeExecute from 'util/safeExecute';
import { getEventTypeById } from 'util/firebase-operations/event_types/get';
import { createTrigger } from 'util/firebase-operations/triggers/create';
import { updateTrigger } from 'util/firebase-operations/triggers/update';
import useFullUrl from 'packages/utils/hooks/useFullUrl';
import { goRouteToTriggers } from 'packages/triggers/constants';
import LocationCombiner, { getLocationCombinerStatus } from 'components/Location/LocationCombiner';
import styles from './styles.module.less';
import getDocData from '../../../utils/firebase/getDocData';
import ModalLabel from '../../../schedules/components/ModalLabel';
import DropDownDivisions from '../../../../components/DropDownDivision';
import { ALLOWED_ROLES, getDivsWithAccess } from '../../../utils/access';
import { DropDownEventsMemo } from '../../../../components/DropDownEvents';
import { eventTypeSummaryPropTypes } from '../../../utils/proptypes/eventTypes';

const { Item } = Form;
const FORMAT = 'HH:mm';
export const FORM_ITEM_LAYOUT = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
};

const INIT_LOCATION = {
  isAGroup: -1,
  data: [],
};

const Trigger = ({ initialData }) => {
  const history = useHistory();

  const [form] = Form.useForm();
  const intl = useIntl();
  const TriggerFormRef = useRef();
  const { triggerId, divId: divisionPerParams } = useParams();
  const { goToPath } = useFullUrl();

  const { orgId } = useParams();
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const [fetching, setFetching] = useState(false);
  const [division, setDivision] = useState(divisionPerParams);
  const [location, setLocation] = useState(INIT_LOCATION);

  const [eventSelected, setEventSelected] = useState(null);
  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  const isEditing = !!triggerId;
  const mainLoading = fetching;

  const handleSave = async values => {
    safeExecute(async () => {
      const { avoidConsecutiveTriggering, cooldown, description, divId, eventType, name } = values;
      const cooldownLikeMinuts = cooldown.hours() * 60 + cooldown.minutes();

      let body = {
        locations: [],
      };

      if (location.isAGroup === -1) {
        body.locations = [];
      } else {
        if (location?.isAGroup && !!location?.data) {
          body.locationGroup = location?.data || {};
        }
        if (!location?.isAGroup) {
          body.locations = location?.data || [];
        }
      }

      setFetching(true);

      if (isEditing) {
        body = {
          ...body,
          name,
          description,
          avoidConsecutiveTriggering,
          cooldown: cooldownLikeMinuts,
          divId,
        };

        await updateTrigger({
          data: body,
          orgId,
          divId,
          triggerId,
        });
      } else {
        body = {
          ...body,
          name,
          description,
          avoidConsecutiveTriggering,
          cooldown: cooldownLikeMinuts,
          divId,
        };
        const eventTypeRes = await getEventTypeById(orgId, divId, eventType);
        if (!eventTypeRes) {
          throw new Error('Event Type does not exist');
        }

        body.eventType = removeAuditFields(eventTypeRes);

        await createTrigger({
          data: body,
          orgId,
          divId,
        });
      }
    })
      .then(() => {
        notification.success({
          message: intl.formatMessage({ id: 'general.save.successful.message' }),
          placement: 'topRight',
        });
        history.goBack();
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({ id: 'general.save.error.message' }),
          placement: 'topRight',
        });
        setFetching(false);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    if (isEditing) {
      const getTrigger = async () => {
        setFetching(true);
        const triggerData = getDocData(
          await getDoc(
            doc(db, 'organizations', orgId, 'divisions', divisionPerParams, 'triggers', triggerId),
          ),
        );
        if (Object.values(triggerData).length > 0) {
          const cooldown = moment()
            .startOf('day')
            .add(+triggerData?.cooldown || 0, 'minutes');

          TriggerFormRef.current.setFieldsValue({
            avoidConsecutiveTriggering: triggerData.avoidConsecutiveTriggering,
            cooldown,
            description: triggerData?.description,
            divId: triggerData?.divId,
            eventType: triggerData?.eventType?.id,
            id: triggerData?.id,
            name: triggerData?.name,
            uid: triggerData?.uid,
          });

          const isAGroup = getLocationCombinerStatus(triggerData);
          const newData = isAGroup ? triggerData?.locationGroup : triggerData?.locations || [];

          setLocation({
            isAGroup,
            data: newData,
          });
          setFetching(false);
        }
      };
      getTrigger();
    }
  }, [divisionPerParams, isEditing, orgId, triggerId]);

  const title = isEditing ? (
    <IntlMessages id="eventTypes.eventType.edit" />
  ) : (
    <IntlMessages id="eventTypes.eventType.create" />
  );

  useEffect(() => {
    form.resetFields();
  }, [form, initialData]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          goBack={() => goToPath(goRouteToTriggers())}
          title={<Title value={title} />}
          actionButtons={[
            {
              label: <IntlMessages id="general.save" />,
              action: form.submit,
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.CREATE,
              disabled: mainLoading,
              type: 'primary',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={mainLoading}>
        <Form
          ref={TriggerFormRef}
          form={form}
          onFinish={handleSave}
          initialValues={{
            cooldown: moment()
              .startOf('day')
              .add(+initialData?.cooldown || 0, 'minutes'),
            description: initialData?.description || '',
            name: initialData?.name || '',
            avoidConsecutiveTriggering: !!initialData?.avoidConsecutiveTriggering,
            eventType: initialData?.eventType || '',
            divId: initialData?.divId || '',
          }}
          name="triggerForm"
          {...FORM_ITEM_LAYOUT}
        >
          <Row gutter={8}>
            <Col xs={24} xl={12} xxl={12}>
              <KeyValueWidget
                label={
                  <Title.LabelForm
                    required
                    value={<IntlMessages id="triggers.view.form.division" />}
                  />
                }
                value={
                  <Item
                    name="divId"
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="general.requiredDivision.error" />,
                      },
                    ]}
                  >
                    <DropDownDivisions
                      options={allowedDivisions}
                      onChange={val => {
                        setDivision(val);
                        setEventSelected(null);
                        setLocation(INIT_LOCATION);
                        TriggerFormRef.current.resetFields(['eventType']);
                      }}
                      value={division}
                      disabled={isEditing}
                    />
                  </Item>
                }
              />
            </Col>

            <Col xs={24} xl={12} xxl={12}>
              <KeyValueWidget
                label={
                  <ModalLabel
                    required
                    label="form.view.selectLabel"
                    description="schedule.form.triggers.modal.eventType.description"
                  />
                }
                value={
                  <Item
                    name="eventType"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'schedule.form.triggers.modal.eventType.requiredMessage',
                        }),
                      },
                    ]}
                  >
                    <DropDownEventsMemo
                      value={eventSelected}
                      onChange={e => setEventSelected(e)}
                      orgId={orgId}
                      divId={division}
                      disabled={isEditing}
                    />
                  </Item>
                }
              />
            </Col>

            <Col xs={24} xl={12} xxl={12}>
              <KeyValueWidget
                label={
                  <ModalLabel
                    required
                    label="triggers.view.form.name"
                    description="schedule.form.triggers.modal.name.description"
                  />
                }
                value={
                  <Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'schedule.form.triggers.modal.name.requiredMessage',
                        }),
                      },
                      {
                        min: 5,
                        max: 100,
                        message: intl.formatMessage({
                          id: 'triggers.view.name.min.max.message',
                        }),
                      },
                    ]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'schedule.form.triggers.modal.name.placeholder',
                      })}
                    />
                  </Item>
                }
              />
            </Col>

            <Col xs={24} xl={12} xxl={12}>
              <KeyValueWidget
                label={
                  <ModalLabel
                    label="triggers.view.form.description"
                    description="schedule.form.triggers.modal.description.description"
                  />
                }
                value={
                  <Item name="description">
                    <Input />
                  </Item>
                }
              />
            </Col>

            <Col xs={24} xl={12} xxl={12}>
              <KeyValueWidget
                label={
                  <>
                    <Title.LabelForm value={<IntlMessages id="general.locations" />} />
                    <Title.Description value={<IntlMessages id="locations.description" />} />
                  </>
                }
                value={
                  <LocationCombiner
                    className="ant-form-item gx-mt-1"
                    value={location}
                    divisionId={division}
                    onChange={setLocation}
                  />
                }
              />
            </Col>
            <Col span={24}>
              <KeyValueWidget
                className={styles.itemsResponsive}
                label={
                  <ModalLabel
                    label="triggers.view.avoidConsecutiveTriggering"
                    description="schedule.form.triggers.modal.avoidConsecutiveTriggering.description"
                  />
                }
                value={
                  <Item name="avoidConsecutiveTriggering" valuePropName="checked">
                    <Switch />
                  </Item>
                }
              />
            </Col>

            <Col span={24}>
              <KeyValueWidget
                className={styles.itemsResponsive}
                label={
                  <ModalLabel
                    required
                    label="triggers.view.cooldown"
                    description="schedule.form.triggers.modal.cooldown.description"
                  />
                }
                value={
                  <Item
                    name="cooldown"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'schedule.form.triggers.modal.cooldown.requiredMessage',
                        }),
                      },
                    ]}
                  >
                    <TimePicker
                      className="gx-w-100"
                      format={FORMAT}
                      minuteStep={5}
                      showNow={false}
                      placeholder={intl.formatMessage({
                        id: 'schedule.form.triggers.modal.cooldown.placeholder',
                      })}
                    />
                  </Item>
                }
              />
            </Col>
          </Row>
        </Form>
      </BoxContainer>
    </BoxContainer>
  );
};

Trigger.defaultProps = {
  initialData: null,
};

Trigger.propTypes = {
  initialData: PropTypes.shape({
    cooldown: PropTypes.number,
    description: PropTypes.string,
    eventType: eventTypeSummaryPropTypes.isRequired,
    name: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(LOC_TYPES)).isRequired,
        name: PropTypes.string.isRequired,
        distance: PropTypes.shape({
          meters: PropTypes.number.isRequired,
          outside: PropTypes.bool.isRequired,
        }),
      }),
    ),
    avoidConsecutiveTriggering: PropTypes.bool,
    divId: PropTypes.string,
  }),
};
export default Trigger;
