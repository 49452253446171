import React from 'react';
import CircleButton from 'components/Buttons/CircleButton';

const LocationCounter = ({ nfc = 0, qr = 0, gps = 0 }) => {
  return (
    <div className="gx-flex-row gx-guarnic-gap-2">
      <div className="gx-flex-row gx-justify-content-center gx-align-items-center">
        <CircleButton type="secondary" iconName="qr_code_scanner" iconSize="sm" />
        <span>{qr}</span>
      </div>
      <div className="gx-flex-row gx-justify-content-center gx-align-items-center">
        <CircleButton type="secondary" iconName="location_on" iconSize="sm" />
        <span>{gps}</span>
      </div>
      <div className="gx-flex-row gx-justify-content-center gx-align-items-center">
        <CircleButton type="secondary" iconName="nfc" iconSize="sm" />
        <span>{nfc}</span>
      </div>
    </div>
  );
};

export default React.memo(LocationCounter);
