import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Drawer, Empty, Form, Input, notification, Select } from 'antd';

import safeExecute from 'util/safeExecute';
import IntlMessages from 'util/IntlMessages';
import { ALLOWED_ROLES } from 'packages/utils';
import BoxContainer from 'components/BoxContainer';
import { InfoCardList } from 'components/InfoCard';
import FilterContainer from 'components/FilterContainer';
import DropDownDivisions from 'components/DropDownDivision';
import Title from 'components/BoxContainer/components/Title';
import LocationCounter from 'components/Location/LocationCounter';
import useGetDivisionsAllowed from 'packages/utils/hooks/useGetDivisionsAllowed';
import {
  FORM_ITEM_LAYOUT,
  GROUP_LOCATIONS_BY_OPTIONS,
  LOC_TYPES,
  LOCATIONS_FORMS,
} from 'packages/locations/constants';

import AvatarLocation from 'components/Location/AvatarLocation';
import { useIntl } from 'react-intl';
import DropDownLocationTypes from 'components/Location/DropDownLocationTypes';
import useGetParams from 'packages/utils/hooks/useGetParams';
import {
  getLocationGroupByDivision,
  getLocationGroupByOrg,
} from 'util/firebase-operations/location_groups/get';
import PropTypes from 'prop-types';
import { useGetLocationsGroup } from 'packages/utils/hooks/collections/useGetLocationsGroup';
import {
  createLocationGroup,
  INITIAL_FILTERS,
  INITIAL_FORM_STATE,
  updateLocationGroup,
} from './constants';

const LocationGroup = ({ groupLocId = null, divisionId = null, onClose = null }) => {
  const history = useHistory();
  const q = useGetParams();
  const intl = useIntl();
  const locationGroupId = groupLocId ?? q.get('locationGroupId');
  const divId = divisionId ?? q.get('divId');
  const isEditing = !!locationGroupId;
  const orgId = useSelector(({ organizations }) => organizations.organization.id);

  const locationsLoaded = useRef([]); // is used as a faithful copy of the locationsAvailable
  const [form] = Form.useForm();
  const [divisionSelected, setDivisionSelected] = useState(null);
  const [toggleForm, setToggleForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataFeched, setDataFetched] = useState(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const titleMessage = isEditing ? (
    <IntlMessages id="locations.edit.groups" />
  ) : (
    <IntlMessages id="locations.create.groups" />
  );

  const { allowedDivs: allowedDivisions } = useGetDivisionsAllowed(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.CREATE,
  );

  const { data: allLocations = [], loading: loadingLocations } = useGetLocationsGroup({
    divisionId: divisionSelected,
    groupType: form.getFieldValue('groupType'),
  });

  const initialValues = useMemo(() => INITIAL_FORM_STATE, []);
  const mainLoading = loading || loadingLocations;

  const onGoBack = () => {
    const action = onClose || history.goBack;
    action();
  };

  const onAddLocation = loc => {
    form.setFieldValue('locations', [...form.getFieldValue('locations'), loc]);

    // Removing loc from locationsAvailable
    form.setFieldValue(
      'locationsAvailable',
      form.getFieldValue('locationsAvailable').filter(location => location.id !== loc.id),
    );
  };

  const onRemoveLocation = loc => {
    form.setFieldValue(
      'locations',
      form.getFieldValue('locations').filter(location => location.id !== loc.id),
    );

    // Adding loc to locationsAvailable
    form.setFieldValue('locationsAvailable', [...form.getFieldValue('locationsAvailable'), loc]);
  };

  const onCreate = async values =>
    safeExecute(async () => {
      const { locations = [], division = '', description = '', name = '' } = values || {};
      setLoading(true);
      return createLocationGroup({
        orgId,
        body: {
          name,
          description,
          locationIds: locations.map(loc => loc.id),
        },
        divisionId: division,
      })
        .then(() => {
          notification.success({
            message: intl.formatMessage({ id: 'general.save.successful.message' }),
            placement: 'topRight',
          });
          onGoBack();
        })
        .catch(() => {
          notification.error({
            message: intl.formatMessage({ id: 'general.save.error.message' }),
            placement: 'topRight',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onUpdate = async values =>
    safeExecute(async () => {
      const { locations = [], division = '', description = '', name = '' } = values || {};

      setLoading(true);
      const body = {
        name,
        description,
        id: locationGroupId,
        locationIds: locations.map(loc => loc.id),
      };
      return updateLocationGroup({
        orgId,
        divisionId: division,
        body,
      })
        .then(() => {
          notification.success({
            message: intl.formatMessage({ id: 'general.save.successful.message' }),
            placement: 'topRight',
          });

          onGoBack();
        })
        .catch(() => {
          notification.error({
            message: intl.formatMessage({ id: 'general.save.error.message' }),
            placement: 'topRight',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const onFinish = values => {
    if (isEditing) {
      onUpdate(values);
    } else {
      onCreate(values);
    }
  };

  const getLocationCounter = callback => {
    const locs = callback('locations');
    const gps = locs.filter(location => location.type === LOC_TYPES.GPS).length;
    const nfc = locs.filter(location => location.type === LOC_TYPES.NFC).length;
    const qr = locs.filter(location => location.type === LOC_TYPES.QR).length;

    return {
      gps,
      nfc,
      qr,
    };
  };

  const onFilter = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  /* NOTE: MANAGE FORM WHEN EDITING */
  useEffect(() => {
    if ((locationGroupId === 'null' && divId === 'null') || !isEditing) return;

    if (divId !== 'null' && locationGroupId !== 'null') {
      const fetch = async () => {
        const data = await getLocationGroupByDivision({
          divisionId: divId,
          orgId,
          locationGroupId,
        });

        if (data) {
          form.setFieldsValue({
            groupType: 'division',
            name: data.name,
            description: data.description,
            locations: data.locations,
            division: divId,
          });
        }
        setLoading(false);
        setDataFetched(true);
        setDivisionSelected(divId);
      };
      fetch();
    } else {
      const fetch = async () => {
        setLoading(true);
        const data = await getLocationGroupByOrg({ locationGroupId, orgId });
        if (data) {
          form.setFieldsValue({
            groupType: 'organization',
            name: data.name,
            description: data.description,
            locations: data.locations,
          });
        }
        setLoading(false);
        setDataFetched(true);
      };
      fetch();
    }
  }, [divId, form, isEditing, locationGroupId, orgId]);

  /* NOTE: MANAGE  LOCATIONS TO SHOW ACCORDING TO GROUP TYPE SELECTED, ej (locations by org o div) */
  useEffect(() => {
    if (loadingLocations) return;
    if (!allLocations) return;

    const locationFiltered = allLocations.filter(
      location => !form.getFieldValue('locations').some(loc => loc.id === location.id),
    );

    form.setFieldValue('locationsAvailable', locationFiltered);
    locationsLoaded.current = locationFiltered;
  }, [allLocations, form, loadingLocations, dataFeched, toggleForm]);

  /** NOTE: MANAGE LOCATIONS AVAILABLE ACCORDING TO FILTERS
 *locationsLoaded.current is used as a faithful copy of the locationsAvailable
  If there are no filters, we show all the locations according to assigned and availables
 */
  useEffect(() => {
    if (!filters.name && filters.type.length === 0) {
      const locsSelected = form.getFieldValue('locations');
      form.setFieldValue(
        'locationsAvailable',
        locationsLoaded.current.filter(
          location => !locsSelected.some(loc => loc.id === location.id),
        ),
      );
      return;
    }

    const locationsAvailable = locationsLoaded.current.filter(
      loc => !form.getFieldValue('locations').some(locSelected => locSelected.id === loc.id),
    );

    const dataFiltered = locationsAvailable.filter(location => {
      const name = location.name.toLowerCase();
      const nameFilter = filters.name.toLowerCase();
      const { type } = location;
      const typeFilter = filters.type;

      return name.includes(nameFilter) && (typeFilter.length === 0 || typeFilter.includes(type));
    });

    form.setFieldValue('locationsAvailable', dataFiltered);
  }, [filters, form]);

  return (
    <Drawer footer={null} closable={false} open>
      <Form
        initialValues={initialValues}
        form={form}
        layout="vertical"
        name={LOCATIONS_FORMS.LOCATION_GROUP}
        {...FORM_ITEM_LAYOUT}
        onFinish={onFinish}
      >
        <BoxContainer content shadow fixed>
          <FilterContainer
            title={<Title.Header value={titleMessage} />}
            goBack={() => onGoBack()}
            buttonItems={[
              {
                iconName: 'save',
                type: 'primary',
                action: () => form.submit(),
                disabled: mainLoading,
              },
            ]}
          />
        </BoxContainer>
        <BoxContainer content loading={mainLoading}>
          <Form.Item
            name="groupType"
            rules={[
              {
                required: true,
                message: <IntlMessages id="form.required" />,
              },
            ]}
            label={<Title.LabelForm value={<IntlMessages id="location.group.by" />} />}
          >
            <Select
              disabled={isEditing}
              options={GROUP_LOCATIONS_BY_OPTIONS.map(opt => ({
                ...opt,
                label: <IntlMessages id={`location.group.by.${opt.value}`} />,
              }))}
              onChange={val => {
                form.setFieldValue('groupType', val);
                form.setFieldsValue({ division: '' });
                form.setFieldsValue({ locations: [], locationsAvailable: [] });
                setFilters(INITIAL_FILTERS);
                setToggleForm(prev => !prev);
              }}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.groupType !== currentValues.groupType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('groupType') === 'division' ? (
                <Form.Item
                  name="division"
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="form.required" />,
                    },
                  ]}
                  label={<Title.LabelForm value={<IntlMessages id="common.division" />} />}
                >
                  <DropDownDivisions
                    disabled={isEditing}
                    options={allowedDivisions}
                    placeholder={<IntlMessages id="common.division" />}
                    onChange={val => {
                      form.setFieldValue('division', val);
                      form.setFieldsValue({ locations: [], locationsAvailable: [] });
                      setFilters(INITIAL_FILTERS);
                      setDivisionSelected(val);
                    }}
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Title.SubTitle
            className="gx-ml-3"
            value={<IntlMessages id="location.group.information.group" />}
          />
          <span className="gx-text-grey gx-ml-3 gx-d-block">
            <IntlMessages id="location.group.information.group.description" />{' '}
          </span>
          <Form.Item
            className="gx-mt-3"
            rules={[
              {
                required: true,
                message: <IntlMessages id="form.required" />,
              },
            ]}
            label={<Title.LabelForm value={<IntlMessages id="location.group.name" />} />}
            name="name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<Title.LabelForm value={<IntlMessages id="location.group.description" />} />}
            name="description"
          >
            <Input />
          </Form.Item>
          <div className="gx-w-100">
            <div className="gx-flex-row gx-w-100 gx-p-3">
              <div className="gx-flex-row gx-flex-1">
                <Title.SubTitle value={<IntlMessages id="location.group.assigned" />} />
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues?.locations?.length !== currentValues?.locations?.length
                }
              >
                {({ getFieldValue }) => <LocationCounter {...getLocationCounter(getFieldValue)} />}
              </Form.Item>
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues?.locations?.length !== currentValues?.locations?.length
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id="form.required" />,
                    },
                  ]}
                  name="locations"
                >
                  {getFieldValue('locations').length === 0 ? (
                    <Empty description={<IntlMessages id="general.empty.state" />} />
                  ) : (
                    getFieldValue('locations')?.map(location => (
                      <InfoCardList
                        key={location.id}
                        avatar={<AvatarLocation type="light" location={location} />}
                        title={location.name}
                        buttonItems={[
                          {
                            iconName: 'delete',
                            action: () => onRemoveLocation(location),
                            type: 'danger',
                          },
                        ]}
                      />
                    ))
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </div>
          <div className="gx-flex-column gx-w-100 gx-p-3 gx-mt-3">
            <div className="gx-flex-row gx-flex-1">
              <Title.SubTitle value={<IntlMessages id="location.group.available" />} />
            </div>
            <div className="gx-mt-3">
              <div className="gx-flex-row gx-w-100">
                <div className="gx-flex-1">
                  <Input.Search onChange={val => onFilter('name', val.target.value)} />
                </div>
                <div className="gx-flex-1">
                  <DropDownLocationTypes
                    value={filters.type}
                    mode="multiple"
                    onChange={val => onFilter('type', val)}
                  />
                </div>
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues?.locationsAvailable?.length !==
                  currentValues?.locationsAvailable?.length
                }
              >
                {({ getFieldValue }) => (
                  <Form.Item noStyle name="locationsAvailable">
                    {getFieldValue('locationsAvailable')?.length === 0 ? (
                      <Empty description={<IntlMessages id="general.empty.state" />} />
                    ) : (
                      getFieldValue('locationsAvailable')?.map(location => (
                        <InfoCardList
                          key={location.id}
                          avatar={<AvatarLocation type="light" location={location} />}
                          title={location.name}
                          buttonItems={[
                            {
                              iconName: 'add',
                              action: () => onAddLocation(location),
                              type: 'primary',
                            },
                          ]}
                        />
                      ))
                    )}
                  </Form.Item>
                )}
              </Form.Item>
            </div>
          </div>
        </BoxContainer>
      </Form>
    </Drawer>
  );
};

LocationGroup.propTypes = {
  groupLocId: PropTypes.string,
  divisionId: PropTypes.string,
  onClose: PropTypes.func,
};

export default LocationGroup;
