import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import IntlMessages from 'util/IntlMessages';
import { QuestionCircleFilled } from '@ant-design/icons';

import Title from 'components/BoxContainer/components/Title';
import styles from './styles.module.less';

const ModalLabel = ({ label, description, required = false }) => {
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={styles.mainContainer}>
      <Title.LabelForm required={required} value={<IntlMessages id={label} />} />
      <Button
        className={classnames(styles.button, 'gx-mb-0 gx-ml-2')}
        type="text"
        onClick={() => setIsVisible(true)}
      >
        <QuestionCircleFilled />
      </Button>
      <Modal
        title={intl.formatMessage({ id: 'schedule.form.triggers.modal.helpModal.title' })}
        open={isVisible}
        footer={null}
        onCancel={() => setIsVisible(false)}
      >
        <h3>
          <IntlMessages id={label} />
        </h3>
        <IntlMessages id={description} />
      </Modal>
    </div>
  );
};

ModalLabel.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default ModalLabel;
