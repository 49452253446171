import { useState, useEffect } from 'react';
import { getDocs, collection, onSnapshot } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { useSelector } from 'react-redux';

const useGetOrgLocationGroups = (dependencies = []) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const orgId = useSelector(({ organizations }) => organizations.organization.id);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setLoading(true);
        const locationGroupsRef = collection(db, 'organizations', orgId, 'location_groups');
        const locationGroupsSnapshot = await getDocs(locationGroupsRef);

        const initialGroups = [];
        const unsubscribes = [];

        locationGroupsSnapshot.docs.forEach(locationGroupDoc => {
          const groupData = locationGroupDoc.data();
          const locationsRef = collection(
            db,
            'organizations',
            orgId,
            'location_groups',
            locationGroupDoc.id,
            'locations',
          );

          const unsubscribe = onSnapshot(locationsRef, snapshot => {
            const locations = snapshot.docs.map(doc => doc.data());
            setGroups(prevGroups => {
              const updatedGroups = prevGroups.map(group => {
                if (group.id === locationGroupDoc.id) {
                  return {
                    ...group,
                    locations,
                  };
                }
                return group;
              });
              return updatedGroups;
            });
          });

          initialGroups.push({
            ...groupData,
            id: locationGroupDoc.id,
            locations: [],
          });

          unsubscribes.push(unsubscribe);
        });

        setGroups(initialGroups);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();

    return () => {
      // Cleanup listeners on unmount
      setGroups(prevGroups => {
        prevGroups.forEach(group => {
          if (group.unsubscribe) {
            group.unsubscribe();
          }
        });
        return [];
      });
    };
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, [orgId, ...dependencies]);

  return { data: groups, loading, error };
};

export default useGetOrgLocationGroups;
