import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Poi from 'components/Location/Poi';
import safeExecute from 'util/safeExecute';
import IntlMessages from 'util/IntlMessages';
import { useIsMobile } from 'packages/utils';
import { FORMS } from 'packages/workOrders/constants';
import { triggerPropType } from 'packages/utils/proptypes/trigger';
import EventName from 'components/TriggersList/component/EventName';
import { locationPropTypes } from 'packages/utils/proptypes/locations';
import {
  getLocationGroupByDivision,
  getLocationGroupByOrg,
} from 'util/firebase-operations/location_groups/get';

import styles from './styles.module.less';

const Task = ({ trigger, locations = [], hasOrder }) => {
  const { isMobile } = useIsMobile();
  const [form] = Form.useForm();
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const [locationOptions, setLocationOptions] = React.useState(locations);

  const locationOptional = trigger?.locationOptional;
  const locationRequired = trigger?.locationRequired;
  const hiddeSelect = trigger?.locations?.length === 0 && locationOptional === false;
  const hasLocationGroup = Object.entries(trigger?.locationGroup || {}).length > 0;

  const auxByRules = {
    disabled: !locationOptional,
    defaultValue: hasLocationGroup
      ? trigger?.locationGroup?.locationIds?.[0]
      : trigger?.locations?.[0]?.id,
  };

  useEffect(() => {
    if (trigger && !hasLocationGroup) {
      const loc = trigger.locations?.[0]?.id;
      form.setFieldsValue({ [trigger.id]: loc });
    }
  }, [form, hasLocationGroup, trigger]);

  // NOTE: If a trigger has a location group, we need to fetch locations by that group
  useEffect(() => {
    if (hasLocationGroup) {
      const fetch = async () => {
        const { divisionId, id: locationGroupId, locationIds } = trigger.locationGroup;

        safeExecute(async () => {
          if (!divisionId && !locationGroupId) {
            return;
          }
          let res;
          if (divisionId && locationGroupId) {
            res = await getLocationGroupByDivision({ orgId, divisionId, locationGroupId });
          } else if (locationGroupId) {
            res = await getLocationGroupByOrg({ orgId, locationGroupId });
          }
          setLocationOptions(res?.locations || []);
          form.setFieldsValue({ [trigger.id]: locationIds?.[0] });
        });
      };
      fetch();
    }
  }, [form, hasLocationGroup, orgId, trigger.id, trigger.locationGroup]);

  return (
    <Form name={`${FORMS.TASK_FORM}_${trigger.id}`} form={form}>
      <div className="gx-d-flex gx-w-100">
        {hasOrder && (
          <div className="gx-flex-column gx-justify-content-center gx-mr-3 gx-font-weight-semi-bold gx-fs-xl gx-mb-1">
            {trigger?.order}
          </div>
        )}
        <div className={classNames('gx-w-100', styles.mainTask)}>
          <div className="gx-w-50 gx-pb-2 gx-pb-xl-0 gx-pb-lg-0">
            <EventName
              eventName={trigger?.name}
              eventColor={trigger.eventType?.color}
              eventIcon={trigger.eventType?.icon}
            />
          </div>
          {!hiddeSelect && (
            <Form.Item
              rules={[{ required: locationRequired, message: <IntlMessages id="form.required" /> }]}
              className="gx-w-100 gx-mb-0"
              name={trigger.id}
            >
              <div className="gx-mw-100 gx-w-100">
                <Select
                  {...auxByRules}
                  placeholder={<IntlMessages id="work.order.tasks.location.placeholder" />}
                  className="gx-w-100"
                  labelInValue
                  optionFilterProp="title"
                  optionLabelProp="title"
                  showSearch
                  onChange={item => {
                    form.setFieldsValue({ [trigger.id]: item?.value || undefined });
                  }}
                  allowClear
                >
                  {locationOptions.map(({ id, name, type }) => (
                    <Select.Option value={id} key={id} title={name}>
                      {isMobile ? (
                        <div>
                          <div>{name}</div>
                          <div>{type}</div>
                        </div>
                      ) : (
                        <Poi name={name} type={type} />
                      )}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          )}
        </div>
      </div>
    </Form>
  );
};

Task.propTypes = {
  trigger: triggerPropType,
  locations: locationPropTypes,
  hasOrder: PropTypes.bool.isRequired,
};

Task.defaultProps = {
  trigger: null,
  locations: [],
};

export default Task;
