import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { USER_ROLES, hasAnyAllowedRole } from 'packages/utils';

import styles from './styles.module.less';

const MenuOption = ({
  as = '',
  className = '',
  selected = false,
  allowedRoles = null,
  ...props
}) => {
  const Element = as || Link;
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const hasAccess = hasAnyAllowedRole(allowedRoles, userOrgAccess, userOrgDivisions);

  return hasAccess ? (
    <Element
      {...props}
      className={classnames(styles.button, className, selected && styles.selected)}
    />
  ) : (
    <> </>
  );
};

MenuOption.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))),
};

export default MenuOption;
