import { auth, db } from 'firebase/firebase';
import { collection, deleteDoc, doc, serverTimestamp, writeBatch } from 'firebase/firestore';

export const INITIAL_FORM_STATE = {
  groupType: '',
  division: '',
  locations: [],
  locationsAvailable: [],
};

export const INITIAL_FILTERS = {
  name: '',
  type: [],
};

export const createLocationGroup = async ({ orgId = '', divisionId = '', body = null }) => {
  if (!orgId || !body) {
    throw new Error('Organization ID and body are required');
  }

  const batch = writeBatch(db);

  // Determine the root reference based on whether a division ID is provided
  const rootRef = divisionId
    ? doc(db, 'organizations', orgId, 'divisions', divisionId)
    : doc(db, 'organizations', orgId);

  const locationGroupRef = doc(collection(rootRef, 'location_groups'));
  const newId = locationGroupRef.id;

  const reqBody = {
    ...body,
    id: newId,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    uid: auth?.currentUser?.uid,
  };
  if (divisionId) {
    reqBody.divisionId = divisionId;
  }

  batch.set(locationGroupRef, reqBody);

  try {
    await batch.commit();
  } catch (error) {
    throw new Error('Failed to create location group');
  }
};

export const updateLocationGroup = async ({ orgId = '', divisionId = '', body = null }) => {
  if (!orgId || !body) {
    throw new Error('Organization ID and body are required');
  }

  const batch = writeBatch(db);

  // Determine the root reference based on whether a division ID is provided
  const rootRef = divisionId
    ? doc(db, 'organizations', orgId, 'divisions', divisionId)
    : doc(db, 'organizations', orgId);

  const locationGroupRef = doc(collection(rootRef, 'location_groups'), body.id);

  batch.update(locationGroupRef, {
    ...body,
    updatedAt: serverTimestamp(),
    uid: auth?.currentUser?.uid,
  });

  try {
    await batch.commit();
  } catch (error) {
    throw new Error('Failed to update location group');
  }
};

export const deleteLocationGroup = async ({ orgId = '', divId = '', groupId = '' }) => {
  if (!orgId || !groupId) {
    throw new Error('Organization ID and group ID are required');
  }

  // Determine the root reference based on whether a division ID is provided
  const rootRef = divId
    ? doc(db, 'organizations', orgId, 'divisions', divId)
    : doc(db, 'organizations', orgId);

  const locationGroupRef = doc(collection(rootRef, 'location_groups'), groupId);

  try {
    await deleteDoc(locationGroupRef);
  } catch (error) {
    throw new Error('Failed to delete location group');
  }
};
