import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Grid, List, Popconfirm, Row } from 'antd';
import LabelValue from '../../LabelValue';
import IntlMessages from 'util/IntlMessages';
import styles from '../styles.module.less';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import EventName from './EventName';
import classNames from 'classnames';
import { ALLOWED_ROLES } from 'packages/utils';
import ComponentBlocker from 'components/ComponentBlocker';
import { eventTypeSummaryPropTypes } from '../../../packages/utils/proptypes/eventTypes';
import LocationElement from 'components/Location/LocationElement';

const { useBreakpoint } = Grid;

const TriggerElement = ({ trigger, divId, className, onEdit, onDelete }) => {
  const divisionId = divId ? divId : trigger.divId;
  const orgId = useSelector(state => state.organizations.organization.id);
  const eventType = trigger?.eventType || null;
  const division = useSelector(
    ({ user }) => user.profile.data.organizations[orgId]?.divisions?.[divisionId]?.name,
  );
  const screens = useBreakpoint();

  const isDesktop = !screens.xs;
  const hasTrigger = Object.values(trigger).length > 0;
  return (
    <List.Item>
      <Card
        actions={
          !isDesktop &&
          hasTrigger && [
            <div>
              {onEdit && (
                <ComponentBlocker
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.UPDATE}
                >
                  <span onClick={() => onEdit(trigger)} className="gx-pointer gx-mr-2">
                    <EditTwoTone className="gx-mr-2" />
                    <IntlMessages id="button.edit" />
                  </span>
                </ComponentBlocker>
              )}
              {onDelete && (
                <ComponentBlocker
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.DELETE}
                >
                  <Popconfirm
                    title={<IntlMessages id="sweetAlerts.areYouSure" />}
                    onConfirm={() => onDelete(trigger)}
                    okText={<IntlMessages id="button.yes" />}
                    cancelText={<IntlMessages id="button.no" />}
                  >
                    <span className="gx-mr-2">
                      <DeleteTwoTone twoToneColor="#eb2f96" className="gx-mr-2" />
                      <IntlMessages id="button.delete" />
                    </span>
                  </Popconfirm>
                </ComponentBlocker>
              )}
            </div>,
          ]
        }
        size="small"
        className={classNames(styles.triggerListBody, className)}
      >
        <Row gutter={[8, 8]}>
          <Col className={styles.triggerElementName} xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
            {eventType && (
              <EventName
                eventName={eventType?.name}
                eventColor={eventType?.color}
                eventIcon={eventType?.icon}
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={10} xxl={14}>
            <Row>
              <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8}>
                <LabelValue
                  vertical={isDesktop}
                  name={<IntlMessages id="schedules.trigger.name.label" />}
                  value={trigger.name}
                />
              </Col>
              {!!trigger.description && (
                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8}>
                  <LabelValue
                    vertical
                    name={<IntlMessages id="schedules.trigger.description.label" />}
                    value={trigger.description}
                  />
                </Col>
              )}
              {!!trigger?.expectedExecutions && (
                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8}>
                  <LabelValue
                    vertical={isDesktop}
                    name={<IntlMessages id="schedules.trigger.expectedExecutions.label" />}
                    value={trigger.expectedExecutions}
                  />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8}>
                <LabelValue
                  vertical={isDesktop}
                  name={<IntlMessages id="schedule.trigger.cooldown.label" />}
                  value={trigger.cooldown}
                />
              </Col>
              {trigger.divId && (
                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8}>
                  <LabelValue
                    vertical={isDesktop}
                    name={<IntlMessages id="triggers.view.division" />}
                    value={division}
                  />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8}>
                <LabelValue
                  vertical={isDesktop}
                  name={<IntlMessages id="schedule.trigger.avoidConsecutiveTriggering.label" />}
                  value={
                    trigger.avoidConsecutiveTriggering ? (
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                    ) : (
                      <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
            {trigger?.locations?.length > 0 &&
              trigger?.locations?.map(loc => (
                <LocationElement type="primary" withoutBackGround key={loc.id} loc={loc} />
              ))}
          </Col>
        </Row>
        <Row gutter={8}>
          {isDesktop && hasTrigger && (
            <Col className="gx-w-100 gx-flex-row gx-justify-content-end gx-pointer">
              {onEdit && (
                <ComponentBlocker
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.UPDATE}
                >
                  <span onClick={() => onEdit(trigger)} className="gx-mr-2">
                    <EditTwoTone className="gx-mr-2" />
                    <IntlMessages id="button.edit" />
                  </span>
                </ComponentBlocker>
              )}
              {onDelete && (
                <ComponentBlocker
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.TRIGGERS.DELETE}
                >
                  <Popconfirm
                    title={<IntlMessages id="sweetAlerts.areYouSure" />}
                    onConfirm={() => onDelete(trigger)}
                    okText={<IntlMessages id="button.yes" />}
                    cancelText={<IntlMessages id="button.no" />}
                  >
                    <span className="gx-mr-2">
                      <DeleteTwoTone twoToneColor="#eb2f96" className="gx-mr-2" />
                      <IntlMessages id="button.delete" />
                    </span>
                  </Popconfirm>
                </ComponentBlocker>
              )}
            </Col>
          )}
        </Row>
      </Card>
    </List.Item>
  );
};

TriggerElement.defaultProps = {
  divId: null,
  onEdit: null,
  onDelete: null,
};
TriggerElement.propTypes = {
  trigger: PropTypes.shape({
    avoidConsecutiveTriggering: PropTypes.bool.isRequired,
    cooldown: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    eventType: eventTypeSummaryPropTypes.isRequired,
    expectedExecutions: PropTypes.number,
    id: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.object,
        distance: PropTypes.shape({
          meters: PropTypes.number,
          outside: PropTypes.bool,
        }),
        divId: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        position: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
          lon: PropTypes.number,
        }),
        type: PropTypes.string,
        uid: PropTypes.string,
        updatedAt: PropTypes.object,
      }),
    ),
    name: PropTypes.string.isRequired,
  }),
  divId: PropTypes.string,
  className: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default TriggerElement;
