import React from 'react';
import { Location } from './Location';

const LocationsItems = ({ locations = [], locationType = 'primary' }) => (
  <div className="gx-flex-xl-row gx-flex-column gx-guarnic-gap-1 gx-align-items-xl-center">
    {locations?.map(loc => (
      <div key={loc?.id}>
        <Location location={loc} type={locationType} />
      </div>
    ))}
  </div>
);

export default LocationsItems;
