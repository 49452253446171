import React from 'react';
import { Avatar, Button, Card, Col, Grid, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux';
import { Buffer } from 'buffer';
import classNames from 'classnames';
import { LOC_TYPES } from '../../packages/locations/constants';
import TitleLinker from '../TitleLinker';
import IntlMessages from 'util/IntlMessages';
import styles from './styles.module.less';
import { AimOutlined, QrcodeOutlined } from '@ant-design/icons';

const downloadQR = () => {
  const svg = document.getElementById('qrcode');
  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = 'qrcode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  const base64Str = Buffer.from(svgData, 'utf8').toString('base64');
  img.src = `data:image/svg+xml;base64,${base64Str}`;
};

const { useBreakpoint } = Grid;
const ICONS = {
  GPS: <AimOutlined />,
  QR: <QrcodeOutlined />,
  NFC: <span style={{ fontSize: '8px' }}>{LOC_TYPES.NFC}</span>,
};

const variants = {
  primary: styles.primary,
  light: styles.light,
};

const LocationElement = ({
  loc,
  actions = [],
  hasGpsDescription = false,
  withoutBackGround = false,
  type = 'primary',
}) => {
  const screens = useBreakpoint();

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);

  const division = useSelector(
    ({ user }) => user.profile.data.organizations[orgId].divisions[loc.divId]?.name,
  );

  const isMobile = screens.xs;
  const intl = useIntl();
  const hasActions = actions?.length > 0;
  const qrModal = content => {
    Modal.info({
      okText: intl.formatMessage({ id: 'button.ok' }),
      maskClosable: true,
      icon: null,
      content: (
        <div>
          <QRCodeSVG
            id="qrcode"
            value={content}
            size="100%"
            onClick={downloadQR}
            style={{ cursor: 'pointer' }}
          />
          <Button onClick={downloadQR} type="primary">
            {intl.formatMessage({ id: 'button.download' })}
          </Button>
        </div>
      ),
    });
  };

  //TODO: When we will take actions about to other locations we going to add here.
  const handleAction = {
    QR: content => qrModal(content),
    NFC: () => null,
    GPS: () => null,
  };

  return (
    <Card
      bodyStyle={{ padding: '0px', margin: '0px' }}
      size="small"
      actions={isMobile && hasActions && actions}
      className={classNames(
        withoutBackGround && styles.withoutBackGround,
        styles.locationCard,
        'gx-mb-0',
      )}
    >
      <Col className="gx-d-flex gx-p-0" span={24}>
        <div className={styles.locationContainer}>
          <div className={styles.avatarContainer}>
            <Avatar
              onClick={() => handleAction[loc.type](loc.token)}
              icon={ICONS[loc?.type?.toUpperCase()]}
              size={24}
              className={classNames(styles.locationAvatar, variants[type || 'primary'])}
            />
          </div>
          <div className={styles.locationText}>
            <TitleLinker titleElements={[loc?.name, loc?.type, division]} />
          </div>
          {hasGpsDescription && loc.type === LOC_TYPES.GPS && (
            <div className={styles.gpsDescription}>
              <IntlMessages
                id="locations.list.location.GPS.description"
                values={{
                  side: (
                    <span className={styles.boldText}>
                      <IntlMessages id={loc.distance.outside ? 'side.outside' : 'side.inside'} />
                    </span>
                  ),
                  meters: msg => <span className={styles.boldText}>{msg}</span>,
                  amount: <span className={styles.boldText}>{loc.distance.meters}</span>,
                }}
              />
            </div>
          )}
        </div>
        {!isMobile && hasActions && (
          <div
            className="gx-d-flex gx-mr-2 gx-width-175 gx-justify-content-end"
            style={{ cursor: 'pointer' }}
          >
            {actions?.map((item, idx) => (
              <div className="gx-pl-3 gx-pt-1" key={idx}>
                {item}
              </div>
            ))}
          </div>
        )}
      </Col>
    </Card>
  );
};

LocationElement.propTypes = {
  loc: PropTypes.shape({
    distance: PropTypes.shape({
      meters: PropTypes.number,
      outside: PropTypes.bool,
    }),
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(LOC_TYPES)).isRequired,
    id: PropTypes.string.isRequired,
    divId: PropTypes.string,
    token: PropTypes.string,
  }).isRequired,
  actions: PropTypes.array,
  hasGpsDescription: PropTypes.bool,
};

export default LocationElement;
