import React from 'react';

import { Select } from 'antd';
import classNames from 'classnames';

import CircleButton from 'components/Buttons/CircleButton';
import DivisionIconLabel from 'components/Division/DivisionIconLabel';
import IntlMessages from 'util/IntlMessages';

function DropDownLocationGroup({ className = '', options = [], ...props }) {
  return (
    <Select
      {...props}
      disabled={!options?.length || props?.disabled}
      optionFilterProp="title"
      showSearch
      className={classNames('gx-w-100 gx-m-0', className)}
      placeholder={<IntlMessages id="general.select.locationsGroup" />}
    >
      {options.map((item, index) => (
        <Select.Option title={item.name} key={index} value={item?.id}>
          <div className="gx-flex-row gx-align-content-center gx-guarnic-gap-2">
            <div className="gx-align-content-center">
              <CircleButton iconName="workspaces" iconSize="sm" />
            </div>
            <div className="gx-flex-row  gx-guarnic-gap-2">
              <div className="gx-align-content-center">{item?.name}</div>
              <div className="gx-align-content-center">
                {item?.divId && <DivisionIconLabel divId={item?.divId} />}
              </div>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default React.memo(DropDownLocationGroup);
