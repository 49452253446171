import { db } from 'firebase/firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

export const getLocationGroupByOrg = async ({ locationGroupId, orgId }) => {
  const locationGroupsRef = doc(db, 'organizations', orgId, 'location_groups', locationGroupId);
  const locationGroupDoc = await getDoc(locationGroupsRef);
  if (locationGroupDoc.exists()) {
    const groupsRef = collection(locationGroupsRef, 'locations');
    const groupsSnapshot = await getDocs(groupsRef);

    const group = {
      ...locationGroupDoc.data(),
      locations: groupsSnapshot.docs.map(groupDoc => ({
        ...groupDoc.data(),
      })),
    };

    return group;
  }

  return null;
};

export const getLocationGroupByDivision = async ({ divisionId, orgId, locationGroupId }) => {
  const locationGroupsRef = doc(
    db,
    'organizations',
    orgId,
    'divisions',
    divisionId,
    'location_groups',
    locationGroupId,
  );
  const locationGroupDoc = await getDoc(locationGroupsRef);
  if (locationGroupDoc.exists()) {
    const groupsRef = collection(locationGroupsRef, 'locations');
    const groupsSnapshot = await getDocs(groupsRef);

    const group = {
      ...locationGroupDoc.data(),
      locations: groupsSnapshot.docs.map(groupDoc => ({
        ...groupDoc.data(),
      })),
    };

    return group;
  }

  return null;
};
