import { db } from 'firebase/firebase';
import safeExecute from 'util/safeExecute';
import { collection, getDocs, query } from 'firebase/firestore';

const getOrgLocations = orgId =>
  safeExecute(async () => {
    const locationsRef = collection(db, 'organizations', orgId, 'locations');
    const snapshot = await getDocs(locationsRef);
    const locations = snapshot.docs.map(doc => doc.data());
    return locations;
  });

const getDivLocations = async (orgId, divisions) =>
  safeExecute(async () => {
    const divQueriesList = divisions.map(divId =>
      query(collection(db, 'organizations', orgId, 'divisions', divId, 'locations')),
    );
    const snapshots = await Promise.all(divQueriesList.map(q => getDocs(q)));
    const locations = snapshots.flatMap(snapshot => snapshot.docs.map(doc => doc.data()));
    return locations;
  });

export { getOrgLocations, getDivLocations };
