import React, { useMemo, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { ALLOWED_ROLES } from 'packages/utils';
import ComponentBlocker from 'components/ComponentBlocker';

import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { collection, orderBy, query } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import useGetOrgLocationGroups from 'packages/utils/hooks/collections/useGetOrgLocationGroups';
import useGetDivLocationGroups from 'packages/utils/hooks/collections/useGetDivLocationGroups';
import { set, get } from 'packages/utils/sessionStorage';
import styles from './styles.module.less';
import OrganizationList from '../../components/Location/OrganizationList';
import DivisionList from '../../components/Location/DivisionList';
import LocationFilter from '../../components/Location/LocationFilter';
import {
  getDivsWithAccess,
  hasAnyAllowedRole,
  useFirestoreQuery,
  useFirestoreQueryBatched,
} from '../../../utils';
import {
  getRouteToCreateLocation,
  getRouteUploadLocationFile,
  goToRouteLocationGroup,
} from '../../constants';

const LocationsList = () => {
  const history = useHistory();
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const loadedLocs = useSelector(({ locations }) => locations.location.save.loaded);
  const tabSelectedStoraged = get('locationsListTab') ?? 'organizationList';
  const divisionTabSelected = tabSelectedStoraged === 'divisionList';
  const orgTabSelected = tabSelectedStoraged === 'organizationList';

  const canListOrg = hasAnyAllowedRole(ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST, userOrgAccess);
  const orgLocationsRef = collection(db, 'organizations', orgId, 'locations');

  const initialFormFilter = {
    locationType: '',
    locationName: '',
    onlyGroups: false,
  };
  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const [refreshToggle, setRefreshToggle] = useState(false);
  const [formFilter, setFormFilter] = useState(initialFormFilter);

  /* *** LOCATIONS BY ORG *** */

  const { data: orgLocations = [], loading: orgLocsLoading } = useFirestoreQuery(
    canListOrg && orgLocationsRef,
    [canListOrg, orgId],
  );
  const { data: orgGroupLocations = [], loading: orgGroupLocationsLoading } =
    useGetOrgLocationGroups([refreshToggle]);

  /* *** LOCATION BY DIV *** */

  // NOTE: only is called when is division tab is selected
  const queriesList =
    divisionTabSelected &&
    allowedSelectedDivs.map(divId =>
      query(
        collection(db, 'organizations', orgId, 'divisions', divId, 'locations'),
        orderBy('createdAt'),
      ),
    );
  const {
    data: divLocs = [],
    loading: divLocsLoading,
    error,
  } = useFirestoreQueryBatched(queriesList, [selectedDivsIds, loadedLocs, refreshToggle]);

  const { data: divGroupLocations, loading: divGroupLocationsLoading } = useGetDivLocationGroups(
    {
      run: divisionTabSelected,
    },
    [divisionTabSelected && refreshToggle],
  );

  const mainLoading = orgTabSelected
    ? orgLocsLoading || orgGroupLocationsLoading
    : divLocsLoading || divGroupLocationsLoading;

  const handleLocation = () => {
    setFormFilter(initialFormFilter);
  };

  const handleFilter = (type, value) => {
    setFormFilter(prev => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleCreateLocation = type => {
    history.push(getRouteToCreateLocation(type), {
      fromOrganization: !!orgTabSelected,
    });
  };

  const organizationData = useMemo(() => {
    const orgLocationsMerged = [...orgLocations, ...orgGroupLocations];
    if (orgLocationsMerged.length === 0) return [];

    if (!formFilter.locationType && !formFilter.locationName && !formFilter.onlyGroups) {
      return orgLocationsMerged;
    }

    return orgLocationsMerged.filter(loc => {
      const matchesType = loc.type === formFilter.locationType || formFilter.locationType === '';
      const matchesName = loc.name.toLowerCase().includes(formFilter.locationName.toLowerCase());
      const matchesOnlyGroups = formFilter.onlyGroups === 'groups' && 'locations' in loc;
      const matchesOnlyLocations = formFilter.onlyGroups === 'locations' && !('locations' in loc);
      const matchesTypeGroup =
        loc?.locations?.length > 0 && loc?.locations?.some(l => l.type === formFilter.locationType);

      return (
        (matchesType || matchesTypeGroup) &&
        matchesName &&
        (matchesOnlyGroups || matchesOnlyLocations || !formFilter.onlyGroups)
      );
    });
  }, [
    orgLocations,
    orgGroupLocations,
    formFilter.locationType,
    formFilter.locationName,
    formFilter.onlyGroups,
  ]);

  const divisionData = useMemo(() => {
    const divLocsMerged = [...divLocs, ...divGroupLocations];

    if (divLocsMerged.length === 0) return [];

    if (!formFilter.locationType && !formFilter.locationName && !formFilter.onlyGroups) {
      return divLocsMerged;
    }

    return divLocsMerged.filter(loc => {
      const matchesType = loc.type === formFilter.locationType || formFilter.locationType === '';
      const matchesName = loc.name.toLowerCase().includes(formFilter.locationName.toLowerCase());
      const matchesOnlyGroups = formFilter.onlyGroups === 'groups' && 'locations' in loc;
      const matchesOnlyLocations = formFilter.onlyGroups === 'locations' && !('locations' in loc);
      const matchesTypeGroup =
        loc?.locations?.length > 0 && loc?.locations?.some(l => l.type === formFilter.locationType);

      return (
        (matchesType || matchesTypeGroup) &&
        matchesName &&
        (matchesOnlyGroups || matchesOnlyLocations || !formFilter.onlyGroups)
      );
    });
  }, [
    divGroupLocations,
    divLocs,
    formFilter.locationName,
    formFilter.locationType,
    formFilter.onlyGroups,
  ]);

  const tabsList = [
    {
      label: <IntlMessages id="locations.list.organizationLocations" />,
      key: 'organizationList',
      children: (
        <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.LIST}>
          <BoxContainer loading={orgLocsLoading}>
            <OrganizationList
              data={organizationData}
              loading={orgLocsLoading}
              refresh={setRefreshToggle}
            />
          </BoxContainer>
        </ComponentBlocker>
      ),
    },
    {
      label: <IntlMessages id="locations.list.divisionsLocations" />,
      key: 'divisionList',
      children: (
        <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATIONS.LIST}>
          <BoxContainer loading={divLocsLoading}>
            <DivisionList data={divisionData} loading={divLocsLoading} refresh={setRefreshToggle} />
          </BoxContainer>
        </ComponentBlocker>
      ),
    },
  ];

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          showHide
          title={<Title value={<IntlMessages id="sidebar.configuration.locations" />} />}
          content={
            <LocationFilter
              filters={formFilter}
              handleFilter={handleFilter}
              handleClear={() => setFormFilter(initialFormFilter)}
            />
          }
          actionButtons={[
            {
              label: <IntlMessages id="locations.upload.file.label" />,
              action: () => history.push(getRouteUploadLocationFile()),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
            {
              label: <IntlMessages id="locations.create.groups" />,
              action: () => history.push(goToRouteLocationGroup()),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
            {
              label: (
                <span className="gx-text-uppercase">
                  <PlusOutlined /> <IntlMessages id="locations.gps" />
                </span>
              ),
              action: () => handleCreateLocation('GPS'),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
            {
              label: (
                <span className="gx-text-uppercase">
                  <PlusOutlined /> <IntlMessages id="locations.nfc" />
                </span>
              ),
              action: () => handleCreateLocation('NFC'),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
            {
              label: (
                <span className="gx-text-uppercase">
                  <PlusOutlined /> <IntlMessages id="locations.qr" />
                </span>
              ),
              action: () => handleCreateLocation('QR'),
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.LOCATIONS.CREATE,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer error={error} content loading={mainLoading}>
        <Tabs
          defaultValue={tabSelectedStoraged ?? 'organizationList'}
          defaultActiveKey={tabSelectedStoraged ?? 'organizationList'}
          onChange={e => {
            handleLocation(e);
            set('locationsListTab', e);
          }}
          className={styles.locationTabsContainer}
          items={tabsList}
        />
      </BoxContainer>
    </BoxContainer>
  );
};

export default LocationsList;
