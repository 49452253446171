import React from 'react';
import DivisionIconLabel from 'components/Division/DivisionIconLabel';
import IntlMessages from 'util/IntlMessages';

import { InfoCardList } from 'components/InfoCard';
import { LOC_TYPES } from 'constants/commons';
import { useIsMobile } from 'packages/utils';
import AvatarLocation from './AvatarLocation';

const LocationInfoCard = ({ location, buttonItems, ...props }) => {
  const { isMobile } = useIsMobile();

  const auxProps = {};
  if (location?.divId) {
    auxProps.subTitle = <DivisionIconLabel divId={location?.divId} />;
  }
  if (buttonItems) {
    auxProps.buttonItems = buttonItems;
  }

  return (
    <InfoCardList
      key={location.id}
      title={
        <div className="gx-flex-row gx-guarnic-gap-2">
          <div className="gx-align-content-center">{location.name}</div>
          {location.type === LOC_TYPES.GPS && (
            <>
              {!isMobile && <span className="gx-toolbar-separator gx-bg-primary">&nbsp;</span>}

              <div>
                <IntlMessages
                  id="locations.list.location.GPS.description"
                  values={{
                    side: (
                      <span className="gx-font-weight-bold">
                        <IntlMessages
                          id={location.distance.outside ? 'side.outside' : 'side.inside'}
                        />
                      </span>
                    ),
                    meters: msg => <span className="gx-font-weight-bold">{msg}</span>,
                    amount: (
                      <span className="gx-font-weight-bold">{location?.distance?.meters}</span>
                    ),
                  }}
                />
              </div>
            </>
          )}
        </div>
      }
      avatar={<AvatarLocation location={location} type="light" />}
      {...auxProps}
      {...props}
    />
  );
};

export default LocationInfoCard;
