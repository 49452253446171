import React from 'react';
import IntlMessages from 'util/IntlMessages';

/**
 * Error codes
 * 1000 for frontend
 * 2000 for backend
 */

export const ERROR_OVER = {
  FRONTEND: 1000,
  BACKEND: 2000,
};

export const BACKEND_ERROR_CODES = {
  FIREBASE_INDEX: 2001,
};

export const ERROR_CODES = {
  PROP_NOT_FOUND: 1001,
};

export const ERROR_MESSAGES = {
  FIREBASE_INDEX: (
    <IntlMessages
      id="error.contact.support"
      values={{ over: ERROR_OVER.BACKEND, code: BACKEND_ERROR_CODES.FIREBASE_INDEX }}
    />
  ),
  PROP_NOT_FOUND: (
    <IntlMessages
      id="error.something.wrong"
      values={{ over: ERROR_OVER.FRONTEND, code: ERROR_CODES.PROP_NOT_FOUND }}
    />
  ),
  DEFAULT: <IntlMessages id="notification.somethingWentWrong" />,
};
