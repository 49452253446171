import React, { memo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';
import { useIntl } from 'react-intl';

const GooglePlaceAutocomplete = ({
  className,
  label,
  onChange,
  onSelect,
  textClassName,
  errors,
  register: { ref, ...rest },
  inputClassName,
}) => {
  const name = rest.name;
  const autoCompleteRef = useRef();
  const Intl = useIntl();

  const register = el => {
    ref(el);
    autoCompleteRef.current = el;
  };

  const clearAddress = () => {
    onChange?.call(null);
  };

  useEffect(() => {
    const ADDRESS_FIELDS = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      sublocality_level_1: 'short_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_2: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
    };

    if (autoCompleteRef.current) {
      const autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
        types: ['geocode'],
      });

      // Prevent form submit on enter
      addEventListener('keydown', e => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          e.preventDefault();
        }
      });

      autoComplete.setFields(['address_component', 'geometry']);

      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace().geometry;
        const lat = place?.location?.lat();
        const lng = place?.location?.lng();

        const newAddress = {};
        const addressComponents = autoComplete.getPlace().address_components;

        Object.keys(ADDRESS_FIELDS).forEach(field => {
          newAddress[field] = '';
        });

        if (lat && lng) {
          newAddress.latitude = lat;
          newAddress.longitude = lng;
        }
        if (addressComponents && (onChange || onSelect)) {
          addressComponents.forEach(component => {
            const addressType = component.types[0];

            if (ADDRESS_FIELDS[addressType]) {
              newAddress[addressType] = component[ADDRESS_FIELDS[addressType]];
            }
          });
          if (onChange) onChange(newAddress);
          if (onSelect) onSelect(newAddress);
        }
      });
    }
  }, [autoCompleteRef, onChange, onSelect]);

  return (
    <div className={classnames(styles.mainContainer, className)}>
      {label && (
        <label className={classnames('m-bottom-2', textClassName)} htmlFor={name}>
          {label}
        </label>
      )}
      <div className="gx-d-flex gx-w-100">
        <div>
          <i className={classnames('icon icon-search', styles.inputIcon)} />
        </div>
        <div className="gx-w-100">
          <input
            autoComplete="off"
            className={classnames(
              'm-bottom-1 ant-input',
              styles.input,
              !!errors[name]?.message && styles.error,
              inputClassName,
            )}
            onInput={clearAddress}
            ref={register}
            {...rest}
            placeholder={Intl.formatMessage({ id: 'form.location.required' })}
          />
          {!!errors[name]?.message && (
            <span className={styles.errorText}>{errors[name]?.message}</span>
          )}
          {rest.required && (
            <span className={classnames(styles.required, textClassName)}>
              <IntlMessages id="form.required" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
GooglePlaceAutocomplete.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  register: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    ref: PropTypes.any.isRequired,

    disabled: PropTypes.bool,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    minLength: PropTypes.number,
    pattern: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ).isRequired,

  onSelect: PropTypes.func,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

GooglePlaceAutocomplete.defaultProps = {
  className: '',
  textClassName: '',
  inputClassName: '',
  onSelect: () => {},
  onChange: () => {},
};

export default memo(GooglePlaceAutocomplete);
