import React, { useEffect } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const DropDownDivisions = ({
  options = [],
  onChange,
  disabled = false,
  placeholder = null,
  ...props
}) => {
  //If only has a division, set it as default value
  useEffect(() => {
    if (options.length === 1 && props.value === undefined) {
      onChange(options?.[0].id);
    }
  }, [options]);

  return (
    <div className="gx-w-100">
      <Select
        {...props}
        disabled={disabled}
        style={{ width: '100%' }}
        dropdownMatchSelectWidth={false}
        onChange={id => onChange(id)}
        optionFilterProp="children"
        placeholder={placeholder}
        showSearch
      >
        {options?.map(div => (
          <Select.Option key={div.id} value={div.id}>
            {div.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

DropDownDivisions.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.any,
};

export default DropDownDivisions;
