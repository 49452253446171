import React from 'react';

import CircleButton from 'components/Buttons/CircleButton';
import GroupLocations from 'packages/locations/screens/GroupLocations';

export default function LocationsGroup({ data }) {
  const [groupLocationsListVisible, setGroupLocationsListVisible] = React.useState(false);

  return (
    <>
      <div className="gx-flex-row gx-align-items-center gx-guarnic-gap-2 gx-mr-2 gx-ml-2">
        <CircleButton
          iconName="workspaces"
          iconSize="sm"
          onClick={() => setGroupLocationsListVisible(true)}
        />
        <div>{data?.name}</div>
      </div>

      {groupLocationsListVisible && (
        <GroupLocations
          divisionId={data?.divisionId}
          locGroupId={data?.id}
          onClose={() => setGroupLocationsListVisible(false)}
        />
      )}
    </>
  );
}
