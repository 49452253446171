/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { db } from 'firebase/firebase';
import { ALLOWED_ROLES } from 'packages/utils/access';
import { collection, onSnapshot, query } from 'firebase/firestore';

import safeExecute from 'util/safeExecute';
import useGetDivisionsAllowed from '../useGetDivisionsAllowed';
import useFirestoreQueryBatched from '../useFirestoreQueryBatched/useFirestoreQueryBatched';

/**
 * Custom hook to fetch location groups for specified divisions.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {Array<string>} params.divIds - Array of division IDs to fetch location groups for.
 * @param {boolean} params.run - Flag to determine if the hook should run.
 * @param {boolean} params.ignoreDivisionsSelector - Flag to ignore the divisions selector.
 * @param {Array<any>} dependencies - Array of dependencies for the hook.
 *
 * @returns {Object} - Returns an object containing the fetched data and loading state.
 * @returns {Array<Object>} data - Array of location groups with their respective locations.
 * @returns {boolean} loading - Loading state indicating if the data is still being fetched.
 */

export default function useGetDivLocationGroups(
  { divIds = [], run = true, ignoreDivisionsSelector = false },
  dependencies = [],
) {
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const [output, setOutput] = useState([]);
  const compareDivs = useRef(divIds);
  const unsubscribeRef = useRef([]);

  const { allowedSelectedDivs, allowedDivs } = useGetDivisionsAllowed(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.LOCATION_GROUPS.LIST,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);

  let divisionIds = ignoreDivisionsSelector ? allowedDivsIds : allowedSelectedDivs; // NOTE: if ignoreDivisionsSelector is true, use all divisions, Ignore general divisions selector

  // NOTE: if has divIds, filter divisionIds
  if (divIds.length > 0) {
    divisionIds = divisionIds.filter(divId => divIds.includes(divId));
  }

  // NOTE: if divisionIds is empty, return empty array, besides if run is false we return empty array and this avoid to fetch data
  const divQueriesList = run
    ? divisionIds.map(divId =>
        query(collection(db, 'organizations', orgId, 'divisions', divId, 'location_groups')),
      )
    : [];

  const changeDivs = JSON.stringify(compareDivs.current) !== JSON.stringify(divIds); // NOTE: compare divIds to re-fetch data since divIds changed

  if (changeDivs) {
    compareDivs.current = divIds;
  }

  const { data: locationGroups = [], loading: locationGroupsLoading } = useFirestoreQueryBatched(
    divQueriesList,
    [orgId, changeDivs, divisionIds.length, ...dependencies],
  );

  useEffect(() => {
    if (!orgId || locationGroups.length === 0 || !run) {
      return setOutput([]);
    }

    const fetchLocations = async () => {
      safeExecute(() => {
        locationGroups.forEach(group => {
          if (!group?.divisionId || !group?.id) return;

          const locationsRef = collection(
            db,
            'organizations',
            orgId,
            'divisions',
            group.divisionId,
            'location_groups',
            group.id,
            'locations',
          );

          const unsubscribe = onSnapshot(locationsRef, snapshot => {
            const locations = snapshot.docs.map(doc => doc.data());
            const groupUpdated = {
              ...group,
              locations: locations ?? [],
            };

            setOutput(prev => {
              const exists = prev.some(
                item =>
                  item.id === groupUpdated.id &&
                  JSON.stringify(item.locations) === JSON.stringify(groupUpdated.locations),
              );
              if (exists) {
                return prev;
              }

              return [...prev.filter(item => item.id !== groupUpdated.id), groupUpdated];
            });
          }); // end onSnapshot

          unsubscribeRef.current.push(unsubscribe);
        });
      });
    };

    fetchLocations();

    const cleanupUnsubscribe = unsubscribeRef.current;
    return () => {
      cleanupUnsubscribe.forEach(unsubscribe => unsubscribe());
    };
  }, [locationGroups?.length, orgId, output?.length, run]);

  return {
    data: output, // NOTE: if locationGroups is empty, output is empty
    loading: locationGroupsLoading,
  };
}
