import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import LocationInfoCard from 'components/Location/LocationInfoCard';
import {
  getLocationGroupByDivision,
  getLocationGroupByOrg,
} from 'util/firebase-operations/location_groups/get';
import BoxContainer from 'components/BoxContainer';
import safeExecute from 'util/safeExecute';

const LocationsByGroup = ({ divisionId = '', locationGroupId = '', onGetLocationsGroup }) => {
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);

  const [groupLocations, setGroupLocations] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      safeExecute(async () => {
        if (!divisionId && !locationGroupId) {
          return;
        }
        setFetching(true);
        let res;
        if (divisionId && locationGroupId) {
          res = await getLocationGroupByDivision({ orgId, divisionId, locationGroupId });
        } else if (locationGroupId) {
          res = await getLocationGroupByOrg({ orgId, locationGroupId });
        }
        if (onGetLocationsGroup) {
          onGetLocationsGroup(res);
        }
        return setGroupLocations(res);
      }).finally(() => {
        setFetching(false);
      });
    };
    fetch();
  }, []);

  return (
    <BoxContainer loading={fetching}>
      {groupLocations?.locations?.length > 0 &&
        groupLocations?.locations?.map(loc => <LocationInfoCard key={loc.id} location={loc} />)}
    </BoxContainer>
  );
};

export default React.memo(LocationsByGroup);
